<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <div class="row full-width q-pa-xl">
      <div class="row col-12 q-mb-xl justify-between">
        <div class="text-h4">{{ reportType.title }} Report</div>
      </div>
      <div class="row full-width justify-between">
        <MediaSelect @input="updateFilterBy" v-bind:selection.sync="filterByDefault" label="Filter Data By" class=" col-3" mode="view" />
        <q-checkbox v-if="reportType.name === 'media'" left-label v-model="showCompletedOnly" label="Show Only Completed" />
        <q-select class="col-3" label="Group By" v-model="selectedGroupBy" :options="groupByOptions" map-options emit-value popup-content-class="bg-lmsBackground primaryText-text" />
        <q-select class="col-3" label="Data For" v-model="dataLengthSelection" :options="dataLengthOptions" popup-content-class="bg-lmsBackground primaryText-text" />
      </div>
      <div v-if="dataLengthSelection.label === 'Custom'" class="row full-width q-mt-sm justify-end">
        <q-input filled v-model="startDate" label="Start Date" @click="() => $refs.qStartDateProxy.show()" class="q-mx-sm">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qStartDateProxy" transition-show="scale" transition-hide="scale">
                <q-date v-model="startDate" @input="() => $refs.qStartDateProxy.hide()" mask="MM/DD/YYYY" :options="startDateOptions" class="bg-lmsBackground primaryText-text" />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input filled v-model="endDate" label="End Date" @click="() => $refs.qEndDateProxy.show()" class="q-mx-sm">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qEndDateProxy" transition-show="scale" transition-hide="scale">
                <q-date v-model="endDate" @input="() => $refs.qEndDateProxy.hide()" mask="MM/DD/YYYY" :options="endDateOptions" class="bg-lmsBackground primaryText-text" />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="row full-width q-mt-xl justify-between">
        <div class="col-4 justify-start">
          <q-select label="Chart Type" v-model="chartType" :options="chartTypeOptions" popup-content-class="bg-lmsBackground primaryText-text" />
        </div>
        <div class="col-8 row q-gutter-lg justify-end">
          <q-btn label="Generate Report" @click="getReportData" color="primary" :disable="disableActions"/>
          <q-btn label="Schedule Report" @click="scheduleReport" outline color="primary" />
          <q-btn flat label="Email Report" icon="mail" @click="emailReport" color="primary" :disable="disableActions"/>
          <q-btn flat label="Download as PDF" icon="get_app" @click="getPDF" color="primary" :disable="disableActions"/>
        </div>
      </div>
    </div>
    <div class="row q-pa-lg full-width">
      <div class="col-12">
        <vue-highcharts :key="componentKey" :highcharts="highcharts" :options="options" ref="chart"></vue-highcharts>
      </div>
    </div>
    <div class="row full-width">
      <div class="col-12 q-pa-md">
        <q-table :title="tableTitle" :data="data" :columns="tableColumns" :pagination.sync="paginationControl" row-key="uid" class="bg-lmsBackground primaryText-text">
          <template v-slot:top-right>
            <q-btn flat label="Download as CSV" icon="get_app" @click="getCSV" color="primary" />
          </template>
        </q-table>
      </div>
    </div>
    <canvas id="canvas" style="display: none;"></canvas>
    <canvas id="myCanvas" style="display: none;"></canvas>

    <q-dialog v-model="showRecipientsModal">
      <AddRecipientsAndSend v-if="report" type="email" :propReport="report" @closeForm="showRecipientsModal = false"/>
    </q-dialog>

    <q-dialog v-model="scheduledReportModal" persistent>
      <ScheduledReportForm v-if="report" :propReport="report" @closeForm="closeForm"/>
    </q-dialog>
  </div>
</template>

<script>
function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

import VueHighcharts from 'vue2-highcharts'
import Highcharts from 'highcharts'
import { date, exportFile } from 'quasar'
import ScheduledReportForm from 'components/ScheduledReportForm'
import AddRecipientsAndSend from 'components/ManageReportSubscribers'
import MediaSelect from 'components/MediaSelect'

require('highcharts/modules/exporting')(Highcharts)

export default {
  name: 'LoginReport',
  components: { ScheduledReportForm, VueHighcharts, AddRecipientsAndSend, MediaSelect },
  data () {
    return {
      showRecipientsModal: false,
      showCompletedOnly: false,
      reportType: {},
      componentKey: 0,
      options: { title: { text: '' } },
      highcharts: Highcharts,
      report: null,
      scheduledReportModal: false,
      dateFormat: 'MMMM',
      customEndDate: null,
      daysToSubtract: 7,
      getWithNewDates: true,
      data: [],
      paginationControl: { rowsPerPage: 30, page: 1 },
      filterByDefault: 'Company',
      selectedFilterBy: 'Company',
      filterByType: 'Company',
      filterByOptions: [
        { label: 'Company', id: 'Company' },
        { label: 'Location', id: 'Location', children: this.$store.getters['locations/treeList'] },
        { label: 'User Group', id: 'Department', children: this.$store.getters['departments/treelist'] }
      ],
      dataLengthSelection: { label: 'Last 7 Days', value: 7 },
      dataLengthOptions: [
        { label: 'Last 7 Days', value: 7 },
        { label: 'Last 30 Days', value: 30 },
        { label: 'Last 90 Days', value: 90 },
        { label: 'Last Year', value: 365 },
        { label: 'Custom', value: 180 }
      ],
      chartType: { label: 'Line', value: 'spline', key: 1 },
      chartTypeOptions: [
        { label: 'Line', value: 'spline', key: 1 },
        { label: 'Bar', value: 'bar', key: 2 },
        { label: 'Column', value: 'column', key: 3 },
        { label: 'Area', value: 'area', key: 4 }
      ],
      reportData: [],
      chartDates: [],
      groups: [],
      selectedGroupBy: 'Locations',
      groupByOptions: [{ label: 'Locations', value: 'Locations' }, { label: 'User Groups', value: 'Departments' }],
      companyGroupByOptions: ['Locations', 'User Groups', 'Users'],
      locationGroupByOptions: ['User Groups', 'Users'],
      departmentGroupByOptions: ['Locations', 'Users'],
      columns: [
        {
          name: 'recordDate',
          label: 'Date',
          align: 'left',
          field: row => date.formatDate(new Date(row.recordDate + ' UTC'), 'MM/DD/YYYY h:mm a'),
          sortable: true,
          showFor: '*'
        },
        {
          name: 'first_name',
          label: 'First Name',
          align: 'left',
          field: row => row.first_name,
          sortable: true,
          showFor: '*'
        },
        {
          name: 'last_name',
          label: 'Last Name',
          align: 'left',
          field: row => row.last_name,
          sortable: true,
          showFor: '*'
        },
        {
          name: 'email',
          label: 'Email',
          align: 'left',
          field: row => row.email,
          sortable: true,
          showFor: 'login'
        },
        {
          name: 'is_finished',
          label: 'Completed',
          align: 'left',
          field: row => row.is_finished === '1' ? 'Yes' : 'No',
          sortable: true,
          showFor: 'media'
        },
        {
          name: 'title',
          label: 'Module',
          align: 'left',
          field: row => row.title,
          sortable: true,
          showFor: 'modules'
        }
      ]
    }
  },
  computed: {
    disableActions () {
      return !date.isValid(this.startDate) || !date.isValid(this.endDate) || this.startDate.length > 10 || this.endDate.length > 10
    },
    tableColumns () {
      return this.columns.filter(col => col.showFor === '*' || col.showFor === this.reportType.name)
    },
    tableTitle: function () {
      return `${this.reportType.tableTitle} (${date.formatDate(this.startDate, 'MM/DD/YYYY')} - ${date.formatDate(this.endDate, 'MM/DD/YYYY')})`
    },
    startDate: {
      get: function () {
        return date.formatDate(date.subtractFromDate(Date.now(), { days: this.daysToSubtract }), 'MM/DD/YYYY')
      },
      set: function (newValue) {
        let newDate = new Date(newValue)
        this.getWithNewDates = true
        this.daysToSubtract = date.getDateDiff(Date.now(), newDate, 'days')
      }
    },
    endDate: {
      get: function () {
        return date.formatDate(this.customEndDate || date.subtractFromDate(Date.now(), { days: 1 }), 'MM/DD/YYYY')
      },
      set: function (newValue) {
        this.customEndDate = new Date(newValue)
        this.getWithNewDates = true
      }
    }
  },
  watch: {
    startDate: function () {
      this.getWithNewDates = true
    },
    dataLengthSelection: function () {
      if (this.dataLengthSelection.label !== 'Custom') {
        this.customEndDate = null
      }
      this.daysToSubtract = this.dataLengthSelection.value
    }
  },
  methods: {
    emailReport () {
      this.report = {
        isNew: true,
        user_uid: this.$store.state.auth.user.uid,
        type: this.reportType.title,
        settings: {
          chartType: this.chartType.value,
          options: {
            groupBy: this.selectedGroupBy,
            filterBy: this.selectedFilterBy,
            isComplete: this.showCompletedOnly
          },
          user_uids: []
        },
        startDate: date.formatDate(this.startDate, 'YYYY/MM/DD'),
        endDate: date.formatDate(this.endDate, 'YYYY/MM/DD')
      }
      this.showRecipientsModal = true
    },
    scheduleReport () {
      this.report = {
        type: this.reportType.title,
        label: '',
        recurring_date: null,
        recurring_duration: null,
        settings: {
          chartType: this.chartType.value,
          duration: this.dataLengthSelection,
          description: null,
          options: {
            groupBy: this.selectedGroupBy,
            filterBy: this.selectedFilterBy,
            isComplete: this.showCompletedOnly
          },
          user_uids: []
        },
        is_active: true,
        isNew: true
      }

      this.scheduledReportModal = true
    },
    startDateOptions (dateOption) {
      return date.formatDate(dateOption, 'YYYY/MM/DD') < date.formatDate(this.endDate, 'YYYY/MM/DD')
    },
    endDateOptions (dateOption) {
      return date.formatDate(dateOption, 'YYYY/MM/DD') > date.formatDate(this.startDate, 'YYYY/MM/DD') && date.formatDate(dateOption, 'YYYY/MM/DD') <= date.formatDate(Date.now(), 'YYYY/MM/DD')
    },
    updateFilterBy (value) {
      this.selectedFilterBy = value.id

      let locationIds = this.$store.getters['locations/treeList'].map(location => location.id)

      this.filterByType = locationIds.includes(value.id) ? 'location' : 'department'
    },
    getCSV () {
      // naive encoding to csv format
      const content = [ this.tableColumns.map(col => wrapCsvValue(col.label)) ].concat(
        this.data.map(row => this.tableColumns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        `${this.reportType.pdfDownloadName}_${date.formatDate(Date.now(), 'YYYY-MM-DD')}.csv`,
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
    async getPDF () {
      this.$q.loading.show()

      let res = await this.$axios.get(`${this.$consts.DOWNLOAD_REPORT_URL}?type=${this.reportType.name}&chartType=${this.chartType.value}&startDate=${date.formatDate(this.startDate, 'YYYY/MM/DD')}&endDate=${date.formatDate(this.endDate, 'YYYY/MM/DD')}&options[groupBy]=${this.selectedGroupBy}&options[filterBy]=${this.selectedFilterBy}&options[isComplete]=${this.showCompletedOnly}`, { responseType: 'arraybuffer' })
      let pdfBlob = new Blob([res], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = URL.createObjectURL(pdfBlob)
      link.download = `${this.reportType.pdfDownloadName}_${date.formatDate(Date.now(), 'YYYY/MM/DD')}.pdf`
      link.click()

      this.$q.loading.hide()
    },
    async getReportData () {
      this.$loading(true, 'Fetching Data')
      let resGrouped = await this.$axios.get(`${this.$consts.GET_REPORT}/${this.reportType.name}?chartType=${this.chartType.value}&startDate=${date.formatDate(this.startDate, 'YYYY/MM/DD')}&endDate=${date.formatDate(this.endDate, 'YYYY/MM/DD')}&options[groupBy]=${this.selectedGroupBy}&options[filterBy]=${this.selectedFilterBy}&options[isComplete]=${this.showCompletedOnly}`)
      let resFull = await this.$axios.get(`${this.$consts.GET_REPORT}/${this.reportType.name}?chartType=${this.chartType.value}&startDate=${date.formatDate(this.startDate, 'YYYY/MM/DD')}&endDate=${date.formatDate(this.endDate, 'YYYY/MM/DD')}&options[filterBy]=${this.selectedFilterBy}&options[isComplete]=${this.showCompletedOnly}`)

      if (resGrouped.plotOptions) {
        resGrouped.plotOptions[this.chartType.value].animation = true // turning animation on for selected chart type
      }

      if (resGrouped.series) {
        this.componentKey = resGrouped.series.length > 1 ? this.chartType.key : 1000 // rendering a new chart if it's a different chart type to get the animation.
      } else {
        this.componentKey = 0
      }

      this.options = resGrouped
      this.$loading(false)
      this.data = resFull
    },
    closeForm (report) {
      this.scheduledReportModal = false
    }
  },
  beforeCreate () {
    this.$store.dispatch('locations/fetch')
    this.$store.dispatch('departments/fetch')
  },
  created () {
    switch (this.$route.params.type) {
      case 'login':
        this.reportType = { title: 'Login', name: 'login', tableTitle: 'Logins', pdfDownloadName: 'loginReport' }
        break
      case 'media':
        this.reportType = { title: 'Media', name: 'media', tableTitle: 'Media Logs', pdfDownloadName: 'mediaLogReport' }
        break
      case 'modules':
        this.reportType = { title: 'Modules', name: 'modules', tableTitle: 'Modules', pdfDownloadName: 'modulesReport' }
        break
      case 'assignment':
        this.reportType = { title: 'Completed Assignments', name: 'assignment', tableTitle: 'Completed Assignments', pdfDownloadName: 'completedAssignmentsReport' }
        break
      // add default to pop dialog
    }

    let chartJs = document.createElement('script')
    let vueChartJs = document.createElement('script')

    chartJs.src = 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.1/Chart.min.js'
    vueChartJs.src = 'https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js'

    document.head.appendChild(chartJs)
    document.head.appendChild(vueChartJs)

    this.getReportData()
  }
}
</script>

<style lang="stylus" scoped>
  .vue-highcharts
    overflow unset !important
</style>
